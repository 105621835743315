// import { setCookies } from '@/services/vaypolCookies/conections';
import axios from '../../services/axiosInstance';
import { getCookies, setCookie } from '../../services/cookies';
import handleCartError from '../../services/handleCartError';
import MA from '../../services/marketingAutomation';

const store = process.env.NEXT_PUBLIC_STORE_ID;

// TODO: Ver compatibilidad de la librería nookies en navegadores antiguos
const create = async (storeId) => {
  try {
    // hacemos la llamada para crear un carrito
    const response = await axios.post(`/cart?code=${storeId}`);
    const cartToken = response.data.data.attributes.token;

    // guardamos el token del carrito en cookie
    setCookie('cartToken', cartToken);

    // si tenemos token, guardamos las cookies en vaypol-cookies
    // const { token } = getCookies();
    // if (token) setCookies();

    // retornamos el carrito
    return {
      success: true,
      data: response.data.data.attributes
    };
  } catch (err) {
    return handleCartError(err);
  }
};

const get = async () => {
  try {
    // si ya existe el carrito
    // lo obtenemos nuevamente del servidor actualizado
    const response = await axios.get(`/cart?code=${store}`);
    const data = response.data.data.attributes;
    if (response.data.data.attributes.item_count > 0) {
      MA.add(data.email, data.token);
    }

    // retornamos el carrito actualizado
    return {
      success: true,
      data
    };
  } catch (err) {
    return handleCartError(err);
  }
};

const addItem = async (
  variantId,
  quantity,
  eventId,
  storeId,
  tryRequest = 0
) => {
  try {
    // verificamos si tiene cartToken
    const { cartToken } = getCookies();
    if (!cartToken) await create(storeId);

    // agregamos el producto al carrito
    const response = await axios.post('/cart/add_item', {
      quantity,
      code: storeId,
      variant_id: variantId,
      event_id: eventId
    });
    const data = response.data.data.attributes;
    // si el carrito tiene items => metemos al usuario a mautic
    if (data.email && data.item_count === 1) {
      MA.add(data.email, data.token);
    }

    // retornamos el carrito actualizado
    return {
      success: true,
      data
    };
  } catch (err) {
    return handleCartError(
      err,
      create,
      addItem,
      [variantId, quantity, eventId, storeId],
      tryRequest
    );
  }
};

const setQuantityItem = async (lineItemId, quantity, storeId) => {
  try {
    // agregamos el producto al carrito
    const response = await axios.patch('/cart/set_quantity', {
      line_item_id: lineItemId,
      code: storeId,
      quantity
    });

    // retornamos el carrito actualizado
    return {
      success: true,
      data: response.data.data.attributes
    };
  } catch (err) {
    return handleCartError(err, create, setQuantityItem, [
      lineItemId,
      quantity,
      storeId
    ]);
  }
};

const removeItem = async (lineItemId, storeId) => {
  try {
    // agregamos el producto al carrito
    const response = await axios.delete(
      `/cart/remove_line_item/${lineItemId}?code=${storeId}`
    );
    const data = response.data.data.attributes;
    // si el carrito no tiene items => sacamos al usuario de mautic
    if (data.email && data.item_count < 1) {
      MA.remove(data.email);
    }

    // retornamos el carrito actualizado
    return {
      success: true,
      data
    };
  } catch (err) {
    return handleCartError(err, create, removeItem, [lineItemId, storeId]);
  }
};

const setCuponCode = async (code, storeId) => {
  try {
    // enviamos la promo al back
    const response = await axios.patch('/cart/apply_coupon_code', {
      coupon_code: code,
      code: storeId
    });
    // retornamos el carrito con la promo
    return {
      success: true,
      data: response.data.data.attributes
    };
  } catch (err) {
    return handleCartError(err, create, setCuponCode, [code]);
  }
};

const finishCart = function () {
  return {
    success: true,
    data: {
      token: null,
      line_items: [],
      item_count: 0,
      total: 0,
      showModal: false,
      loading: false,
      errors: null
    }
  };
};

export default {
  create,
  get,
  addItem,
  setQuantityItem,
  removeItem,
  setCuponCode,
  finishCart
};
