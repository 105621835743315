import axios from '../../services/axiosInstance';
// import errorHandler from '../../services/error/handler';

const getByEmail = async (email) => {
  try {
    const response = await axios.get(
      `/marketing_automation/by_email?email=${email}`
    );

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    return {
      success: false,
      status: err.response.status
    };
  }
};

const createContact = async (email, name) => {
  try {
    const response = await axios.post('/marketing_automation/leads', {
      lead: {
        email,
        first_name: name
      }
    });

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    // const message = errorHandler(err, true);

    return {
      success: false
      // error: message
    };
  }
};

const updateContact = async (contactId, contactData) => {
  try {
    const response = await axios.put(
      `/marketing_automation/leads/${contactId}/edit_cart`,
      {
        lead: contactData
      }
    );

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    // const message = errorHandler(err);

    return {
      success: false
      // error: message
    };
  }
};

const editContactSegments = async (contactId, code, add = true) => {
  try {
    const response = await axios.put(
      `/marketing_automation/leads/${contactId}/edit_abandoned_segment`,
      {
        code,
        add_abandoned_cart: add
      }
    );
    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    // const message = errorHandler(err);

    return {
      success: false
      // error: message
    };
  }
};

const updateSegments = async (contactId, segments, add) => {
  try {
    const response = await axios.put(
      `/marketing_automation/leads/${contactId}/edit_segments`,
      {
        segment_ids: segments,
        add_segments: add
      }
    );

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    // const message = errorHandler(err);

    return {
      success: false
      // error: message
    };
  }
};

export default {
  getByEmail,
  updateContact,
  createContact,
  editContactSegments,
  updateSegments
};
